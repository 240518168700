
import { defineComponent } from 'vue'
import Card from '@/components/Card.vue'

export default defineComponent({
  name: 'App',
  components: {
    Card
  },
  setup() {
    return {}
  }
})
